// import React from "react";
import { urlSafeString } from "./urlSafeString";
import { slug2path } from "./slug2path";
import deepmerge from "deepmerge";

const removeNullValuesFromDeepObject = (inputValue) => {
  if (typeof inputValue === "object" && !Array.isArray(inputValue) && inputValue !== null) {
    return Object.keys(inputValue).reduce((obj, key) => {
      const newKeyValue = removeNullValuesFromDeepObject(inputValue[key]);
      if (newKeyValue) return { ...obj, [key]: newKeyValue };
      return obj;
    }, {});
  } else {
    return inputValue;
  }
};

const languageMerge = (data, targetLocale) => {
  data = JSON.parse(JSON.stringify(data));
  if (typeof data?.allMarkdownRemark === "undefined" || data?.allMarkdownRemark?.edges?.length < 2) {
    if (
      data.allMarkdownRemark.edges.length > 0 &&
      data.allMarkdownRemark.edges[0]?.node?.frontmatter?.tags?.length > 0
    ) {
      data.allMarkdownRemark.edges[0].node.frontmatter.tags =
        data.allMarkdownRemark.edges[0].node.frontmatter.tags.reduce((p, tag) => {
          return [...p, { label: tag.replace(/_/g, " "), value: urlSafeString(tag) }];
        }, []) || [];
    }
    return data;
  }

  const items = {};
  const outputItems = [];

  data.allMarkdownRemark.edges
    .map(({ node }) => ({
      node: node,
      locale: node.fields.slug.replace(/^.*\.(\w{2,3})\//g, "$1"),
      path: slug2path(node.fields.slug),
    }))
    .forEach(({ path, node, locale }) => {
      items[path] = [...(items[path] || []), { locale, node }];
    });

  Object.keys(items).forEach((path) => {
    if (items[path].length === 1) {
      // If there is just one locale per path, we're not going to manipulate it
      outputItems.push({ node: items[path][0].node });
    } else {
      const rootItem = items[path].find(({ locale }) => locale === "sk");
      if (
        rootItem.node.frontmatter.tags?.length > 0 &&
        typeof rootItem.node.frontmatter.tags[0].value === "undefined"
      ) {
        rootItem.node.frontmatter.tags =
          rootItem.node.frontmatter.tags.reduce((p, tag) => {
            return [...p, { label: tag.replace(/_/g, " "), value: urlSafeString(tag) }];
          }, []) || [];
      }

      /* 
             localized_data.tags =
            main_lang_data?.tags?.reduce(
            (p, main_tag, index) => [
                ...p,
                {
                label:
                    (localized_data?.tags || [])[index] ||
                    main_tag.replace(/_/g, " "),
                value: urlSafeString(main_tag),
                },
            ],
            []
            ) || [];
        }

        */

      const targetItem = items[path].find(({ locale }) => locale === targetLocale);

      if (targetLocale !== "sk" && targetItem) {
        if (targetItem && targetItem.node.frontmatter && rootItem.node.frontmatter.tags?.length > 0) {
          targetItem.node.frontmatter.tags =
            rootItem.node.frontmatter.tags.reduce((p, { label, value }, index) => {
              return [
                ...p,
                {
                  label: (targetItem?.node?.frontmatter?.tags || [])[index] || label,
                  value,
                },
              ];
            }, []) || [];
        }
        const item = {
          node: deepmerge(rootItem.node, removeNullValuesFromDeepObject(targetItem).node, {
            arrayMerge: (destination, source, options) => source,
          }),
        };
        // prevent reordering by translation
        item.node.frontmatter.dateOrder = rootItem.node.frontmatter.dateOrder;
        console.log({ a: rootItem.node, b: removeNullValuesFromDeepObject(targetItem).node });
        outputItems.push(item);
      } else {
        outputItems.push({ node: rootItem.node });
      }
    }
  });

  console.log({ outputItems });

  const output = {
    ...data,
    allMarkdownRemark: {
      edges: outputItems,
    },
  };
  return output;
};

export { languageMerge };
