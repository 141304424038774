import React from "react";

const ScrollScaleHero = ({ children }) => {
  const [scale, setScale] = React.useState(1);

  const requestRef = React.useRef();
  const previousTimeRef = React.useRef();

  const animate = React.useCallback((time) => {
    if (previousTimeRef.current !== undefined) {
      // const deltaTime = time - previousTimeRef.current;
      setScale((1 - window.scrollY / window.innerHeight) * 0.2 + 0.8);
    }
    previousTimeRef.current = time;
    requestRef.current = requestAnimationFrame(animate);
  }, []);

  React.useEffect(() => {
    requestRef.current = requestAnimationFrame(animate);
    return () => cancelAnimationFrame(requestRef.current);
  }, [animate]); // Make sure the effect runs only once

  return (
    <div style={{ transformOrigin: "bottom", transform: `scale(${scale})` }}>
      {children}
    </div>
  );
};

export default ScrollScaleHero;
